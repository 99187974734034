export default
    [
        {
            template_id: 1,
            title: "Header with 2 Images",
            component: "Template1",
            fields: ["title", "subTitle", "image_1", "image_2", "background_style"],
            // fileInputFields: ["image_1", "image_2"]
        },
        {
            template_id: 2,
            title: "Title with Content",
            component: "Template2",
            fields: ["title", "content", "image_1", "background_style"],
            // fileInputFields: ["image_1"]
        },
        {
            template_id: 3,
            title: "Title with Content-1",
            component: "Template3",
            fields: ["title", "content", "image_1", "background_style"],
            // fileInputFields: ["image_1"]
        },
        {
            template_id: 4,
            title: "Header with Youtube",
            component: "Template4",
            fields: ["title", "subTitle", "image_1", "video_1", "background_style"],
            // fileInputFields: ["image_1"]
        },
    ]
